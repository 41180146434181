import api from "./api";

const vacancies = {
    getVacancies(query) {
        return api.get('/vacancies', { params: query});
    },
    getVacancy(id, query) {
        return api.get(`/vacancies/${id}`, { params: query});
    },
    getCloseVacancy(id, body) {
        return api.post(`/vacancies/${id}/close`, body);
    },
    deleteVacancy(id) {
        return api.delete(`/vacancies/${id}`);
    },
    getCreateVacancies() {
        return api.post('/vacancies/create');
    },
    getCreateTemplates() {
        return api.post('/vacancies/create?is_template=1');
    },
    postCreateTemplate(body) {
        return api.post('/vacancies/createTemplate', body);
    },
    getFieldsVacancies(id) {
        return api.get(`vacancies/${id}/fields`);
    },
    putVacancies(id, body) {
        return api.put(`/vacancies/${id}`, body);
    },
    putTemplates(id, body) {
        return api.put(`/vacancies/${id}`, body);
    },
    createVacancies(id, body) {
        return api.post(`/vacancies/${id}/publish`);
    },
    createTemplate(id, query) {
        return api.get(`/vacancies/${id}`, { params: query});
    },
    sendFileAttachments(id, body) {
        return api.post(`/attachments`, body);
    },
    sendFile(id, body) {
        return api.post(`/vacancies/${id}/send-files`, body);
    },
    deleteFile(vacancyId, id) {
        return api.delete(`/vacancies/${vacancyId}/delete-files/${id}`);
    },
};

export default vacancies;
