<template>
    <div class="questionnaire">
        <router-link :to="route.query.pageType === 'card' ? `vacancies/${route.query.id}` : `vacancies/${route.query.id}/edit`" class="questionnaire__back">
            <img src="@/assets/images/icons/back-arrow.svg" alt="back">
            <p v-if="route.query.pageType === 'card'">Карточка вакансии</p>
            <p v-else>Создание вакансии</p>
        </router-link>
        <h1 class="questionnaire__title main-title">Расширенная анкета</h1>
        <div class="questionnaire__wrapper container">
            <div class="questionnaire__block">
                <div v-for="(field, index) in fields" :key="index" class="questionnaire__block-section">
                    <div class="questionnaire__block-title">{{ field.title }}</div>

                    <div class="questionnaire__block-content">
                        <div v-for="(field, index) in field.fields"
                             :key="index"
                             class="questionnaire__block-field"
                             :class="{'questionnaire__block-field_subfields': field.subfields?.length}"
                        >
                            <div class="questionnaire__block-field-label">{{ field.title }}</div>
                            <div class="questionnaire__block-field-value">{{ field.type }}</div>

                            <div v-if="field.subfields" class="questionnaire__block-subfields">
                                <div v-for="(subfield, index) in field.subfields" :key="index" class="questionnaire__block-subfield">
                                    <div class="questionnaire__block-subfield-label">{{ subfield.title }}:</div>
                                    <div class="questionnaire__block-subfield-value">{{ subfield.type }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import vacancies from "@/api/vacancies";

const route = useRoute()
const vacancyId = ref(route.params.id);

const fieldsVacancies = ref({});
const fields = ref([])
const getFieldsVacancies = async () => {
    try {
        // fieldsVacancies.value = await vacancies.getFieldsVacancies({
        //     vacancy_id: vacancyId.value,
        // });
        fieldsVacancies.value = await vacancies.getFieldsVacancies(vacancyId.value);
        for (let key in fieldsVacancies.value.forms?.extended_form) {
            fields.value = fieldsVacancies.value.forms?.extended_form[key].groups
        }
    } catch (error) {
        console.log(error);
    }
};

onMounted(() => {
    getFieldsVacancies()
    window.scrollTo(0, 0);
})
</script>

<style scoped lang="scss">
.questionnaire {
    padding-bottom: 47px;

    &__back {
        margin-top: 12px;
        display: flex;
        align-items: center;
        color: #8F9CAC;
        text-decoration: none;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;

        p {
            margin-left: 4px;
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__title {
        margin-top: 8px;
    }

    &__block {
        width: 100%;
        padding: 24px;
        margin-top: 24px;
        border-radius: 16px;
        background-color: #fff;
    }

    &__block-section {
        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    &__block-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
    }

    &__block-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
    }

    &__block-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
    }

    &__block-field {
        width: 100%;
        height: 60px;
        margin-top: 16px;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: $light;

        &_subfields {
            height: auto;
        }
    }

    &__block-subfields {
        margin-top: 24px;
    }

    &__block-subfield {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }

    &__block-subfield-label {
        color: $gray;
        font-size: 12px;
        font-weight: 500;
        line-height: 16.94px;
    }

    &__block-subfield-value {
        width: 140px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        color: $gray;
    }

    &__block-field-label {
        color: $gray;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
    }

    &__block-field-value {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        color: $gray;
    }
}
</style>
